/**
 * The syslog login app Vue3 version
 * Christian Kuster, CH-8342 Wernetshausen, christian@kusti.ch
 * Created: 27.10.23
 **/

import {createApp} from 'vue'
import LoginRoot from "./components/LoginRoot.vue";
import PrimeVue from 'primevue/config';

//import 'primevue/resources/themes/md-light-deeppurple/theme.css'            ;    //core css
import '../lib/style/theme.css'            ;    //core css
import 'primeicons/primeicons.css'                      ;    //icons
import '/node_modules/primeflex/primeflex.css'          ;    //primeflex
import 'primeicons/primeicons.css';

import '../lib/style/app.scss';
import locale from '../lib/de.json';


console.log('This is the login app');

const app = createApp();
app.component('login-root', LoginRoot);
//app.use(PrimeVue, {locale: locale['de-ch']});
app.use(PrimeVue); //, {locale: locale['de-ch']});
app.mount('#login-app')

console.log('should be done!');
